<template>
    <div class="header primary--bg container">
        <div class="row justify-space-between align-center">
            <div>
                <div class="row">
                    <div class="font-24">Sắp xếp theo:</div>
                    <div v-for="(item, index) in data.filters" :key="index">
                        <div class="row align-center">
                            <div class="filters" @click="sort(item)">
                                <div
                                    :class="`row justify-center px-3 ${
                                        item.order > 0 ? 'secondary--text' : ''
                                    }`"
                                >
                                    <div :class="`btn-filter filter-${index}`">
                                        {{ item.text }}
                                    </div>
                                    <i
                                        :class="`mdi mdi-${
                                            item.order == 1
                                                ? 'arrow-up'
                                                : item.order == 2
                                                ? 'arrow-down'
                                                : ''
                                        }`"
                                        v-if="item.order > 0"
                                    ></i>
                                </div>
                            </div>
                            <div
                                style="
                                    width: 1px;
                                    height: 24px;
                                    background-color: #e0e0e0;
                                "
                            ></div>
                        </div>
                    </div>
                </div>
            </div>
            <DxButton
                id="btn-refresh"
                text=""
                type="normal"
                class="btn-style"
                styling-mode="text"
                icon="mdi mdi-refresh"
                height="50px"
                width="50px"
                @click="layDsChuyenDiTheoLoai"
            />
        </div>
        <!--=============================chip ngay==================================-->
        <div class="row mt-4 justify-space-between">
            <div
                v-for="(item, index) in data.chips"
                :key="index"
                :class="`mr-2 ${item.active ? 'chip-active' : ''}`"
            >
                <DxButton
                    :id="`chip-${index}`"
                    :text="item.text"
                    class="rounder chips white--bg"
                    type="default"
                    styling-mode="contained"
                    height="50px"
                    @click="filter(item)"
                />
            </div>
        </div>
    </div>
</template>
<script>
import { DxButton } from "devextreme-vue";
import { mapMutations, mapActions, mapGetters } from "vuex";
import moment from "moment";
export default {
    components: { DxButton },
    props: {},
    data() {
        return {
            data: {
                filters: [
                    {
                        id: 1,
                        text: "Giờ xuất bến",
                        order: 0,
                        truongSapXep: "gioXuatBenDuKien",
                        kieuSapXep: "",
                    },
                    {
                        id: 2,
                        text: "Đánh giá",
                        order: 0,
                        truongSapXep: "danhGiaTrungBinh",
                        kieuSapXep: "",
                    },
                    {
                        id: 3,
                        text: "Giá tiền",
                        order: 0,
                        truongSapXep: "giaVeMin",
                        kieuSapXep: "",
                    },
                    {
                        id: 4,
                        text: "Nhà xe",
                        order: 0,
                        truongSapXep: "nhaXe_TenNhaXe",
                        kieuSapXep: "",
                    },
                ],
                chips: [],
            },
        };
    },
    computed: {
        ...mapGetters("timKiemChuyenDi", ["getLaChuyenDiSapXuatBen"]),
    },
    methods: {
        ...mapMutations("timKiemChuyenDi", [
            "setSapXepDsChuyenDi",
            "setLocNgayDsChuyenDi",
        ]),
        ...mapActions("timKiemChuyenDi", [
            "layDsChuyenDiTheoLoai",
        ]),
        sort(item) {
            this.data.filters.forEach((m) => {
                if (item.id == m.id) {
                    m.order = m.order >= 2 ? 0 : m.order + 1;
                    m.order == 1
                        ? (m.kieuSapXep = "ASC")
                        : m.order == 2
                        ? (m.kieuSapXep = "DESC")
                        : (m.kieuSapXep = "");
                } else {
                    m.order = 0;
                }
            });
            this.setSapXepDsChuyenDi(this.data.filters);
            this.layDsChuyenDiTheoLoai();
        },
        filter(item) {
            this.data.chips.forEach((m) => {
                if (item.id == m.id) {
                    m.active = true;
                } else {
                    m.active = false;
                }
            });
            this.setLocNgayDsChuyenDi(item.value);
            this.layDsChuyenDiTheoLoai();
        },
        setDate() {
            let date = new Date();
            let datee = new Date(date.setDate(date.getDate() - 1));
            let id = 0;
            for (let i = 0; i < 7; i++) {
                let newDate = datee.setDate(datee.getDate() + 1);
                this.data.chips.push({
                    id: id++,
                    text: moment(newDate).format("DD/MM/YY"),
                    active: i == 0 ? true : false,
                    value: moment(newDate).format("yyyy-MM-DD"),
                });
            }
        },
    },
    mounted() {
        this.setDate();
    },
    created() {},
};
</script>
<style scoped>
</style>
<style lang='scss' scoped>
.header {
    border-radius: 30px;
    height: 190px;
    color: white;
}
.btn-filter {
    height: 32px;
    font-size: 24px;
}
::v-deep.btn-style .dx-icon {
    width: 40px;
    height: 40px;
    line-height: 40px;
}
::v-deep.btn-style i {
    font-size: 40px;
    color: white;
}
::v-deep.chips span {
    font-size: 22px;
    color: $text;
}
.filters:active {
    background-color: #3879a8ad;
    border-radius: 10px;
}
.chip-active > .chips {
    background-color: $secondary !important;
}
::v-deep.chip-active > .chips span {
    color: white !important;
}
</style>